import React from "react";
import { StaticQuery, graphql } from "gatsby";
import FixtureCard from "./fixtureCard";

export default function NextFixture({ ClubBadge, detailsBelow }) {
  return (
    <>
      <StaticQuery
        query={graphql`
          query NextFixtureQuery {
            allGoogleGamesTrainingFinesSheet(
              filter: { team: { ne: "Training" }, scoreUs: { eq: null } }
              sort: { fields: date, order: ASC }
              limit: 1
            ) {
              edges {
                node {
                  id
                  Time: formattedDate
                  OpponentName: team
                  Conceded: scoreThem
                  Goals: scoreUs
                  result
                  HomeAway: home_Away
                  urlPath: gameID
                  Location: venue
                  type
                  OpponentBadge: badge
                }
              }
            }
          }
        `}
        render={(data) => (
          <div className="container">
            <h2 className="h4 mb-2 text-center text-lg-right">Next Game</h2>
            {data.allGoogleGamesTrainingFinesSheet.edges.map((edge) => (
              <FixtureCard
                // bg="bg-primary"
                key={"next" + edge.node.id}
                date={edge.node.Time}
                venue={edge.node.Location}
                type={edge.node.type}
                homeOrAway={edge.node.HomeAway}
                opponent={edge.node.OpponentName}
                vsBadge={edge.node.OpponentBadge}
                vsBadgeFull={edge.node.OpponentBadge}
                scoreUs={edge.node.Goals}
                scoreThem={edge.node.Conceded}
                ClubBadge={ClubBadge}
                detailsBelow={detailsBelow}
              />
            ))}
          </div>
        )}
      />
    </>
  );
}
