import React from "react";
import Image from "../Hacks/Image";

// import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby";

//import Fade from "react-reveal/Fade";
// import Pulse from "react-reveal/Pulse";

// import { AttentionSeeker, Fade } from "react-awesome-reveal";

import badge from "../../../static/img/SSHAFC-BADGE-BLACK-PINK.svg";

export const HomeHero = ({
  intro_hello,
  intro_title,
  intro_intro,
  ctaLink,
  ctaText,
  cta2Link,
  cta2Text,
  intro_image,
  logo_title,
  logos,
}) => {
  return (
    <React.Fragment>
      <header id="hero" className="hero hero_home hero-alt3 bg-primary">
        <div className="position-relative container-fluid">
          <div className="row justify-content-center">
            <div className="col-4 col-lg-1">
              <div className="hero-systemimage floaty mt-lg-0 position-relative">
                {/* <Fade> */}
                <Image
                  image={badge}
                  loading="eager"
                  alt="Our Club"
                  className="img-fluid w-100"
                  height="120"
                  width="120"
                />
                {/* </Fade> */}
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-lg-8 col-xl-7 my-md-2 text-center floaty z-index-100 pb-5 pb-lg-0">
              <h1 className="display-2 mb-2 text-white text-uppercase pb-">
                {/* <Fade> */}
                {intro_hello && (
                  <div className="mt-3 text-secondary h3">{intro_hello}</div>
                )}
                {/* </Fade> */}
                {/* <Fade top> */}
                <div
                  className="d-lg-block p-0"
                  dangerouslySetInnerHTML={{ __html: intro_title }}
                />
                {/* </Fade> */}
              </h1>

              {/* <Fade> */}
              <p className="lead text-white mb-3">{intro_intro}</p>
              {/* </Fade> */}
              {/* <AttentionSeeker effect="pulse"> */}
              {/* <Fade> */}
              <div>
                {ctaLink && (
                  <Link to={ctaLink} className="btn btn-secondary mt-1 mx-2">
                    {ctaText}
                  </Link>
                )}
                {cta2Link && (
                  <>
                    <Link
                      to={cta2Link}
                      className="btn btn-outline-secondary mt-1 mx-2 ml-lg-0"
                    >
                      {cta2Text}
                    </Link>
                  </>
                )}
              </div>
              {/* </Fade> */}
              {/* </AttentionSeeker> */}
            </div>

            <div className="col-12">
              <div className="" data-scroll-speed-y="-20">
                {intro_image && (
                  // <Fade>
                  <Image
                    image={intro_image}
                    loading="eager"
                    alt="Our Club"
                    className="img-fluid w-100"
                  />
                  // </Fade>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="afterheader" className="w-100 shadow-lg pt-3 bg-secondary"></div>
      {logos && (
        <section className="container-fluid bg-primary">
          <div className="row">
            <div className={`col-lg-12 bg mt-4 mt-lg-5 pb-lg-5`}>
              {/* <Fade> */}
              <section
                id="partners"
                className="text-md-right text-center container px-0"
              >
                {logo_title && (
                  <h2 className="h4 text-center mb-4">{logo_title}</h2>
                )}
                <div className="row justify-content-center">
                  {logos.map(function (logo, i) {
                    return (
                      <div className="col-2 px-0" key={"partner" + i}>
                        <Image
                          image={logo}
                          backgroundColor="transparent"
                          placeholder="blurred"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
              </section>
              {/* </Fade> */}
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default HomeHero;
