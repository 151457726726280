import React from "react";
import { StaticQuery, graphql } from "gatsby";
import FixtureCard from "./fixtureCard";

export default function LastResult({ ClubBadge, detailsBelow }) {
  return (
    <>
      <StaticQuery
        query={graphql`
          query LastResultQuery {
            allGoogleGamesTrainingFinesSheet(
              filter: { team: { ne: "Training" }, scoreUs: { gt: -1 } }
              sort: { fields: date, order: DESC }
              limit: 1
            ) {
              edges {
                node {
                  id
                  Time: formattedDate
                  OpponentName: team
                  Conceded: scoreThem
                  Goals: scoreUs
                  result
                  HomeAway: home_Away
                  urlPath: gameID
                  Location: venue
                  type
                  OpponentBadge: badge
                }
              }
            }
          }
        `}
        render={(data) => (
          <div className="container">
            <h2 className="h5 mb-2 text-center text-lg-right">Last Game</h2>
            {data.allGoogleGamesTrainingFinesSheet.edges.map((edge) => (
              <FixtureCard
                // bg='bg-primary'
                key={"latest" + edge.node.id}
                date={edge.node.Time}
                venue={edge.node.Location}
                type={edge.node.type}
                homeOrAway={edge.node.HomeAway}
                opponent={edge.node.OpponentName}
                vsBadge={edge.node.OpponentBadge}
                vsBadgeFull={edge.node.OpponentBadge}
                scoreUs={edge.node.Goals}
                scoreThem={edge.node.Conceded}
                path={`/results/${edge.node.urlPath}`}
                ClubBadge={ClubBadge}
                detailsBelow={detailsBelow}
              />
            ))}
          </div>
        )}
      />
    </>
  );
}
