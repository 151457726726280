import React from "react";
import { Link } from "gatsby";
import PricingCardListItem from "./PricingCardListItem";

export const PricingCard = ({
  card,
  cards,
  sliderValue,
  isOpened,
  managementCostMonthly,
  managementCostAnnual,
  i,
  generationCostMonthly,
  generationCostAnnual,
  leadAmount,
  Content,
}) => {
  return (
    <div
      className={`col-12 col-sm-6 px-lg-2 ${
        sliderValue === 4 ? "col-lg-4" : "col-lg-3"
      } mb-4 ${i <= 1 && sliderValue === 4 ? "d-none" : ""}`}
      key={`price+${i}`}
    >
      <div className={`card shadow h-100 p-0`}>
        <div className={`card-header bg-white p-0 mb-lg-4`}>
          <div className={`bg-${card.color} py-2 d-block w-100`}></div>
          <div className="mt-4 px-4">
            <h3 className="my-0">{card.title}</h3>
            <p className={`mb-0 small`}>{card.subtitle}</p>
          </div>
        </div>
        <div
          className={`card-body px-4 ${
            !card.card_monthly_price ? "pt-lg-2 pt-xl-4" : "pt-lg-0"
          }`}
        >
          {card.card_monthly_price && (
            <div className="pricing_type mb-3 pricing_type__month card-title pricing-card-title">
              {!isNaN(card.card_monthly_price) && (
                <React.Fragment>
                  <span className="h3">
                    £
                    {i === 0
                      ? (isOpened
                          ? managementCostMonthly
                          : managementCostAnnual
                        ).toLocaleString()
                      : i === 1
                      ? (isOpened
                          ? generationCostMonthly
                          : generationCostAnnual
                        ).toLocaleString()
                      : 0}
                  </span>
                  <span>
                    <small className="text-secondary small"> per month</small>
                    <div className={`text-primary small`}>
                      Billed {isOpened ? "Monthly" : "Yearly"}
                    </div>
                  </span>
                </React.Fragment>
              )}
            </div>
          )}
          <Link
            cover={`true`}
            direction="right"
            bg="#1F1A3C"
            to="/contact/"
            className={`btn btn-sm btn-outline-primary  ${
              !card.card_monthly_price ? "mt-lg-5" : ""
            }`}
          >
            Enquire
          </Link>
          <hr className={`mt-5border-${card.color}`} />
          <ul className="fa-ul text-left mt-3 ml-4 mt-5">
            {/* show a value of leads for first card or if maxed out leads */}
            {(sliderValue === 4 || i === 0) && (
              <PricingCardListItem
                color={card.color}
                ii={false}
                bold={true}
                feature={leadAmount.toLocaleString() + " leads*"}
              />
            )}

            {/* if maxed out leads, show all features on last card */}
            {sliderValue === 4 &&
              card.card_features &&
              cards[0].card_features
                .split("\n")
                .concat(cards[1].card_features.split("\n").splice(1))
                .map(function (feature, ii) {
                  return (
                    <PricingCardListItem
                      color={card.color}
                      ii={ii}
                      feature={feature}
                    />
                  );
                })}

            {card.card_features &&
              card.card_features.split("\n").map(function (feature, ii) {
                return (
                  (sliderValue === 4 && ii !== 0) ||
                  (sliderValue !== 4 && (
                    <PricingCardListItem
                      color={card.color}
                      ii={ii}
                      feature={feature}
                    />
                  ))
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
