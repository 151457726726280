import React, { useState } from "react";
import { Link } from "gatsby";
import Content from "../Hacks/Content";

import PricingSlider from "./PricingSlider";
import PricingCard from "./PricingCard";

export const PricingTable = ({
  cards,
  something_else_title,
  something_else_cta_url,
  something_else_cta_text,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  function monthly() {
    setIsOpened(1);
  }
  function yearly() {
    setIsOpened(0);
  }

  const [leadAmount, setLeadAmount] = useState(100000);
  const [sliderValue, setSliderValue] = useState(2);

  const [managementCostMonthly, setManagementCostMonthly] = useState(1199);
  const [managementCostAnnual, setManagementCostAnnual] = useState(999);
  const [generationCostMonthly, setGenerationCostMonthly] = useState(1499);
  const [generationCostAnnual, setGenerationCostAnnual] = useState(1249);

  function sliderChange(e) {
    var sliderValue = parseInt(e.target.value);

    var leadAmount = 0;
    var ManagementCostMonthly = 0;
    var ManagementCostAnnual = 0;
    var GenerationCostMonthly = 0;
    var GenerationCostAnnual = 0;

    switch (sliderValue) {
      case 0:
        leadAmount = 20000;
        ManagementCostMonthly = 499;
        ManagementCostAnnual = 399;
        GenerationCostMonthly = 699;
        GenerationCostAnnual = 599;
        break;
      case 1:
        leadAmount = 50000;
        ManagementCostMonthly = 739;
        ManagementCostAnnual = 599;
        GenerationCostMonthly = 999;
        GenerationCostAnnual = 779;
        break;
      case 2:
        leadAmount = 100000;
        ManagementCostMonthly = 1199;
        ManagementCostAnnual = 999;
        GenerationCostMonthly = 1499;
        GenerationCostAnnual = 1249;
        break;
      case 3:
        leadAmount = 250000;
        ManagementCostMonthly = 1799;
        ManagementCostAnnual = 1499;
        GenerationCostMonthly = 1899;
        GenerationCostAnnual = 1599;
        break;
      case 4:
        leadAmount = "250,000+";
        ManagementCostMonthly = 0;
        ManagementCostAnnual = 0;
        GenerationCostMonthly = 0;
        GenerationCostAnnual = 0;
        break;
      default:
        leadAmount = 0;
        ManagementCostMonthly = 0;
        ManagementCostAnnual = 0;
        GenerationCostMonthly = 0;
        GenerationCostAnnual = 0;
        break;
    }
    setManagementCostMonthly(ManagementCostMonthly);
    setManagementCostAnnual(ManagementCostAnnual);
    setGenerationCostMonthly(GenerationCostMonthly);
    setGenerationCostAnnual(GenerationCostAnnual);
    setLeadAmount(leadAmount);
    setSliderValue(sliderValue);
  }

  return (
    <section className="pricing-table pt-0">
      <div className="container-fluid pricing-table-pricing">
        <PricingSlider
          sliderChange={sliderChange}
          sliderValue={sliderValue}
          leadAmount={leadAmount}
          isOpened={isOpened}
          monthly={monthly}
          yearly={yearly}
        />

        <div className="row contained-xl mb-3 text-center text-lg-left justify-content-center pricing_cards">
          <div className="col-lg-10">
            <div className="row justify-content-center">
              {cards.map(function (card, i) {
                return (
                  <PricingCard
                    i={i}
                    card={card}
                    cards={cards}
                    sliderValue={sliderValue}
                    isOpened={isOpened}
                    managementCostMonthly={managementCostMonthly}
                    managementCostAnnual={managementCostAnnual}
                    generationCostMonthly={generationCostMonthly}
                    generationCostAnnual={generationCostAnnual}
                    leadAmount={leadAmount}
                    Content={Content}
                  />
                );
              })}

              <div
                className={`col-12 col-sm-6 mb-4 order-last ${
                  sliderValue === 4 ? "col-lg-4" : "col-lg-3"
                }`}
              >
                <div className="card h-100 bg-primary lg-rounded">
                  <div className="card-body px-4 pt-4 mt-3">
                    <h3 className="mb-3">{something_else_title}</h3>
                    <Link
                      cover={`true`}
                      direction="right"
                      bg="#1F1A3C"
                      to={something_else_cta_url}
                      className="btn btn-outline-white btn-sm mt-4"
                    >
                      {something_else_cta_text}
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className={`col-12 small text-right order-2 mb-5 mb-lg-0 order-sm-last  ${
                  sliderValue === 4 ? "d-none" : ""
                }`}
              >
                <p>
                  <strong>*</strong> Additional leads will cost more.
                  <br />
                  <strong>**</strong> May incur additional costs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingTable;
