import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import HeroIntro from "./General/HeroIntro";

import HomeHero from "./Home/HomeHero";
import LearnMore from "./General/LearnMore";
import Newsletter from "./General/Newsletter";

import LogoLockup from "./General/LogoLockup";

import Stats from "./General/Stats";
import FAQBlock from "./General/FaqBlock";
import SimpleCTA from "./General/SimpleCTA";
import GeneralContent from "./General/GeneralContent";
import ImageText from "./General/ImageText";
import LargeQuote from "./General/LargeQuote";
import PricingTable from "./Pricing/PricingTable";

import SidedList from "./General/SidedList";
import Images from "./General/Images";
import LastResult from "./fixtures/LastResult";

import { Fade } from "react-awesome-reveal";
import NextFixture from "./fixtures/NextFixture";
import BlogNext from "./Blog/BlogNext";

const ModuleLayout = ({ page, blocks, bodyClass, url }) => {
  return (
    <React.Fragment>
      {blocks &&
        blocks.map(function (block, i) {
          if (!block.type) {
            return false;
          } else if (block.type === "hero") {
            return (
              <HeroIntro
                key={"block" + i}
                headerType={block.headerType}
                subtitle={block.header0}
                title2={block.header1}
                title1={block.header2}
                massiveHero={block.massiveHero}
                excerpt={block.intro}
                content={block.content}
                classes={``}
                ctaLink={block.cta_1_link}
                ctaText={block.cta_1_text}
                cta2Link={block.cta_2_link}
                cta2Text={block.cta_2_text}
                image1={block.image}
                // categories={categories}
              />
            );
          } else if (block.type === "latestnews") {
            return (
              <Fade key={"block" + i}>
                <StaticQuery
                  query={graphql`
                    query LastResultNewsQuery {
                      allMarkdownRemark(
                        limit: 4
                        filter: { fields: { slug: { regex: "//blog//" } } }
                        sort: { order: DESC, fields: frontmatter___date }
                      ) {
                        edges {
                          node {
                            id
                            timeToRead
                            fields {
                              slug
                            }
                            frontmatter {
                              date(formatString: "MMMM DD, YYYY")
                              title
                              excerpt
                              image {
                                publicURL
                                childImageSharp {
                                  gatsbyImageData(placeholder: NONE)
                                }
                              }
                              # tags
                            }
                          }
                        }
                      }
                    }
                  `}
                  render={(data) => (
                    <BlogNext
                      title="Latest News"
                      posts={data.allMarkdownRemark.edges}
                      background="primary"
                      cols={3}
                      container={11}
                    />
                  )}
                />
              </Fade>
            );
          } else if (block.type === "fixture_results") {
            return (
              <Fade key={"block" + i}>
                <div className="">
                  <div className="">
                    <NextFixture />
                    <LastResult />
                  </div>
                  <div className="pb-5 text-center">
                    <Link
                      className="btn mx-2 btn-lg btn-secondary"
                      to="/results/"
                    >
                      All Results
                    </Link>
                    <Link
                      className="btn mx-2 btn-lg btn-outline-secondary"
                      to="/fixtures/"
                    >
                      All Fixtures
                    </Link>
                  </div>
                </div>
              </Fade>
            );
          } else if (block.type === "home_hero") {
            return (
              <HomeHero
                key={"block" + i}
                intro_hello={block.header1}
                intro_title={block.header2}
                intro_intro={block.intro}
                intro_image={block.image}
                testimonial_image={block.testimonialImage}
                testimonial_from={block.testimonialFrom}
                testimonial={block.testimonialContent}
                ctaLink={block.cta_1_link}
                ctaText={block.cta_1_text}
                cta2Link={block.cta_2_link}
                cta2Text={block.cta_2_text}
                logo_title={block.logo_title}
                logos={block.logos}
              />
            );
          } else if (block.type === "cards") {
            return (
              <Fade key={"block" + i}>
                <LearnMore title={block.title} cards={block.cards} />
              </Fade>
            );
          } else if (block.type === "stats") {
            return (
              <Fade key={"block" + i}>
                <Stats
                  stats={block.stats}
                  statsTitle={block.statsTitle}
                  statsIntro={block.statsIntro}
                  background={block.background}
                />
              </Fade>
            );
          } else if (block.type === "faqs") {
            return (
              <Fade key={"block" + i}>
                <FAQBlock
                  title={block.title}
                  intro={block.intro}
                  faqs={block.faqs}
                  ctaUrl={block.cta_1_link}
                  ctaText={block.cta_1_text}
                  background={block.background}
                />
              </Fade>
            );
          } else if (block.type === "logolockup") {
            return (
              <Fade key={"block" + i}>
                <LogoLockup
                  title={block.title}
                  logos={block.logos}
                  fullHeight={block.fullHeight}
                />
              </Fade>
            );
          } else if (block.type === "simple_cta") {
            return (
              <Fade key={"block" + i}>
                <SimpleCTA
                  title={block.title}
                  cta_1_link={block.cta_1_link}
                  cta_1_text={block.cta_1_text}
                />
              </Fade>
            );
          } else if (block.type === "large_quote") {
            return (
              <Fade key={"block" + i}>
                <LargeQuote
                  quote={block.testimonialContent}
                  quotee={block.testimonialFrom}
                  quotee_title={block.quotee_title}
                  largequote_image={block.testimonialImage}
                  background={block.background}
                />
              </Fade>
            );
          } else if (block.type === "image_text") {
            return (
              <Fade key={"block" + i}>
                <ImageText
                  blockId={i}
                  title={block.title}
                  subtitle={block.subheading}
                  content={block.content}
                  imagetext_image={block.image}
                  image_order={block.image_leftright}
                  align={block.align}
                  cta_1_text={block.cta_1_text}
                  cta_1_link={block.cta_1_link}
                  cta_1_button={block.cta_1_button}
                  // sticky_top={block.sticky_top}
                  // negative_margin={block.negative_margin}
                  // negative_margin_double={block.negative_margin_double}
                />
              </Fade>
            );
          } else if (block.type === "pricing_table") {
            return (
              <Fade key={"block" + i}>
                <PricingTable
                  card_1_title={block.cta_1_text}
                  card_2_title={block.cta_2_text}
                  cards={block.cards}
                  something_else_title={block.header2}
                  something_else_cta_url={block.cta_3_link}
                  something_else_cta_text={block.cta_3_text}
                />
              </Fade>
            );
          } else if (block.type === "general_content") {
            return (
              <Fade key={"block" + i}>
                <GeneralContent
                  full_width={block.full_width}
                  content={block.body}
                  backgroundColour={block.background}
                  alignment={block.align}
                  // colour={block.attrs.data.colour}
                  // pull_link_title={block.attrs.data.pull_link_title}
                  // pull_link_content={block.attrs.data.pull_link_content}
                  // pull_link_read_more={block.attrs.data.pull_link_read_more}
                  // blockId={i}
                />
              </Fade>
            );
          } else if (block.type === "sidedList") {
            return (
              <Fade key={"block" + i}>
                <SidedList
                  cards={block.cards}
                  title={block.title}
                  intro={block.intro}
                  ctaLink={block.cta_1_link}
                  ctaText={block.cta_1_text}
                  backgroundColour={block.background}
                  numbers={block.numbers}
                  columns={block.columns}
                />
              </Fade>
            );
          } else if (block.type === "images") {
            return (
              <Fade key={"block" + i}>
                <Images images={block.images} />
              </Fade>
            );
          } else if (block.type === "newsletter") {
            return (
              <Fade key={"block" + i}>
                <Newsletter
                  title={block.title}
                  content={block.content}
                  color={block.color}
                  portalId={block.portalId}
                  formId={block.formId}
                />
              </Fade>
            );
          } else return <div>{block.type}</div>;
        })}
    </React.Fragment>
  );
};

export default ModuleLayout;
