import React from 'react'
import { Link } from 'gatsby'

export const SimpleCTA = ({ title, cta_1_link, cta_1_text }) => {
  return (
    <section className="simplecta container-fluid">
      <div className="py-5 row justify-content-center text-center">
        <div className="col-lg-6 col-md-10">
          <h3>
            {title}
            <div>
              {cta_1_link && !cta_1_link.includes('mailto:') ? (
                <Link
                  className="btn btn-success mt-3"
                  to={cta_1_link}
                >
                  {cta_1_text}
                </Link>
              ) : (
                <a className="btn text-white btn-success" href={cta_1_link}>
                  {cta_1_text}
                </a>
              )}
            </div>
          </h3>
        </div>
      </div>
    </section>
  )
}

export default SimpleCTA
